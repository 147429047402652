import axios from 'axios';
const jwtToken = localStorage.getItem('jwtToken');
export const instance = axios.create({
  // baseURL: 'http://192.168.1.9:3002',
  baseURL: 'https://app.digiccard.com/',
  // baseURL: 'http://127.0.0.1:3002/',

  // headers: {
  //   Authorization: `Bearer ${jwtToken}`,
  //   "Content-Type": "application/json",
  // },
});
