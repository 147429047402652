import React, { useState, useEffect } from 'react';

import { login, loginRequest } from '../redux/actions';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { instance } from '../axios-api/axios-api';

const ChangePassword = () => {
  const navigate = useNavigate();
  const jwtToken = localStorage.getItem('jwtToken');

  const [formData, setFormData] = useState({
    current_password: '',
    password: '',
    confirm_password: '',
  });
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const logout = () => {
    // Remove the JWT token from local storage
    localStorage.removeItem('jwtToken');

    // Redirect the user to the login page
    navigate('/login');
  };

  const handleInputChange = event => {
    let { id, value } = event.target;

    setFormData({ ...formData, [id]: value });
  };

  // const handleSubmit = async e => {
  //   e.preventDefault();
  //   const LoginRequest = loginRequest();
  //   dispatch(LoginRequest);
  //   await login(email, password).then(val => {
  //     dispatch(val);
  //   });
  // };

  const handleSubmit = async e => {
    e.preventDefault();
    let isValid = true;

    if (!formData.current_password) {
      isValid = false;
      toast.error('Current Password is Mandatory');
    }

    if (!formData.password) {
      isValid = false;
      toast.error('Password is Mandatory');
    }

    if (formData.confirm_password !== formData.password) {
      isValid = false;
      toast.error('Password must be same');
    }
    if (!jwtToken) {
      isValid = false;
      toast.error('Page is expired,Please login');
      setTimeout(() => {
        logout();
      }, 3000);
    }

    if (isValid) {
      setLoading(true);
      instance.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken;
      instance.defaults.headers.post['Content-Type'] = 'application/json';
      return instance
        .post('/user/change-password', formData)
        .then(response => {
          setLoading(false);
          console.log(response);
          toast.success('Password is Changed');
          setFormData({
            current_password: '',
            password: '',
            confirm_password: '',
          });
        })
        .catch(error => {
          setLoading(false);
          setError(error.message);
          const err = error?.response?.data?.message
            ? error.response.data.message
            : error?.message;
          toast.error(err);
        });
    }
  };

  return (
    <div>
      <header>
        {/* <span className="logo">
        <img src="../assets/images/logo.svg" alt="logo"/>
    </span> */}

        <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-0">
          <div className="container-fluid">
          <Link to="/">
          
              <span className="logo mt-0">
                <img src="../assets/images/logo.svg" alt="logo" />
              </span>
          
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link className="nav-link active" to="/add-user">
                    Edit 
                  </Link>
                  {/* <a className="nav-link active" aria-current="page" href="#">Home</a> */}
                </li>
                {/* <li className="nav-item">
        <Link className='nav-link' to="/about">About</Link>
        </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link" to="tel:+971542747073">Contact</Link>
                  {/* <a className="nav-link" href="tel:+971542747073">Contact</a> */}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      {/* <div className="body-style">
      <div className="wrapper"> */}
      <section className="w-100 heightFix py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-md-1 loginForm-container regCont mt-5 mt-md-0">
              <div className="d-block d-md-none text-center mb-4">
                <h1 className="fw-bold  d-none d-md-block">
                  Register to your account.
                </h1>
              </div>
              <div className="card rounded">
                <div className="card-body p-3 p-md-5">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label htmlFor="current_password" className="form-label">
                        Current Password
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        id="current_password"
                        value={formData.current_password}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={formData.password}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="mb-4">
                      <label htmlFor="confirm_password" className="form-label">
                        Confirm Password
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="confirm_password"
                        value={formData.confirm_password}
                        onChange={handleInputChange}
                      />
                    </div>

                    {/* <!-- <div className="mb-4">
                                      <input type="checkbox" className="form-check-input" id="remember" />
                                      <label for="remember" className="form-label">Remember Me</label>
                                    </div> --> */}
                    <div className="row">
                      <div className="col-md-6 order-md-1 text-center text-md-start">
                        {isLoading ? (
                          <p>Loading...</p>
                        ) : (
                          <button className="btn btn-gradient" type="submit">
                            Change Password
                          </button>
                        )}
                        {error && <p>{error}</p>}
                        {/* <button type="submit" className="btn btn-gradient">Login</button> */}
                      </div>
                    </div>
                    <div>
                      <ToastContainer theme="dark" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* </div>
    </div> */}
    <footer>
        <ul className="fnav">
          <li>
            {/* <Link to="mailto:digiccard@gmail.com">Digiccard@gmail.com</Link> */}
            <a
              href="mailto:digiccard@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Digiccard@gmail.com
            </a>
          </li>
          <li >
                  <Link to="/terms_and_condition">
                  Terms & Condition
                  </Link>
                </li>
         
          {/* <li>
            <Link to="tel:+971542747073">Digiccard@gmail.com</Link>
            <a href="tel:+971542747073">Contact</a>
          </li> */}
        </ul>
        {/* <span className="powered">Powered by  
        <a href="" className='ms-2'>Adzoob Digital Solutions</a>
    </span> */}
      </footer>
    </div>
  );
};

export default ChangePassword;
