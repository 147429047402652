import React, { useState, useEffect } from 'react';

import { login, loginRequest } from '../redux/actions';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { instance } from '../axios-api/axios-api';

const RegisterForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password:'',
    confirm_password:'',
    one_time_password:'',
    user_id:'',
    terms_and_condition:false,
  });
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);

  // const handleSubmit = async e => {
  //   e.preventDefault();
  //   const LoginRequest = loginRequest();
  //   dispatch(LoginRequest);
  //   await login(email, password).then(val => {
  //     dispatch(val);
  //   });
  // };


  const handleSubmit = async e => {
    e.preventDefault();
    
    let isValid =true
    if(formData.confirm_password !== formData.password){
      isValid = false;
      toast.error('Password must be same');
    }
    if (formData.terms_and_condition == false || formData.terms_and_condition == 'false') {
      isValid = false;
      toast.error('please accept terms and condition');
    }
   if( isValid ){
    setLoading(true);
    return instance
      .post('/user/register', formData)
      .then(response => {
        toast.success(response.statusText)
        localStorage.removeItem('jwtToken');
        setLoading(false);
        setTimeout( navigate('/login'),3000)
       
       
      })
      .catch(error => {
        setLoading(false);
        setError(error.message);
        const err = error?.response?.data?.message ?error.response.data.message : error?.message;
        toast.error(err);
       
      
      });
   }
   
  };

  const handleInputChange = event => {
 
    let { id, value } = event.target;
    if(id ==="terms_and_condition"){
      value= (value =='false')?true:false;
  
    }


    setFormData({ ...formData, [id]: value });
  };

  return (
    <div>
   <header>
        {/* <span className="logo">
        <img src="../assets/images/logo.svg" alt="logo"/>
    </span> */}

        <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-0">
          <div className="container-fluid">
          <Link to="/">
            
              <span className="logo mt-0">
                <img src="../assets/images/logo.svg" alt="logo" />
              </span>
           
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link className="nav-link active" to="/">
                    Home
                  </Link>
                  {/* <a className="nav-link active" aria-current="page" href="#">Home</a> */}
                </li>
                {/* <li className="nav-item">
        <Link className='nav-link' to="/about">About</Link>
        </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/login">
                    Login
                  </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link" to="tel:+971542747073">Contact</Link>
                  {/* <a className="nav-link" href="tel:+971542747073">Contact</a> */}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
   
   
        <section className="w-100 heightFix py-5">
          <div className="container">
            <div className="row">
           
              <div className="col-md-6 order-md-1 loginForm-container regCont mt-5 mt-md-0">
                <div className="d-block d-md-none text-center mb-4">
                  <h1 className="fw-bold  d-none d-md-block">Register to your account.</h1>
                </div>
                <div className="card rounded">
                  <div className="card-body p-3 p-md-5">
                    <form onSubmit={handleSubmit}>
                      <div className="mb-4">
                        <label htmlFor="username" className="form-label">
                          Email
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          id="email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          value={formData.password}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="confirm_password"
                          className="form-label"
                        >
                          Confirm Password
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="confirm_password"
                          value={formData.confirm_password}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="mb-4">
                        <label htmlFor="user_id" className="form-label">
                          Digic Id
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="user_id"
                          value={formData.user_id}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="one_time_password"
                          className="form-label"
                        >
                          One Time Password
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="one_time_password"
                          value={formData.one_time_password}
                          onChange={handleInputChange}
                        />
                      </div>


                      <div className="mb-4">
                        <label
                          htmlFor="terms_and_condition"
                          className="form-label"
                        >
                          I have read and accept <Link to="/terms_and_condition">T&C</Link>
                        </label>
                        <input
                          type="checkbox"
                          
                          className="form-check-input checkbox_style"
                          id="terms_and_condition"
                          checked={formData.terms_and_condition}
                          value={formData.terms_and_condition}
                          onChange={handleInputChange}
                        />
                      </div>

                    
                      <div className="row">
                        <div className="col-md-6 order-md-1 text-center text-md-start">
                          {isLoading ? (
                            <p>Loading...</p>
                          ) : (
                            <button className="btn btn-gradient" type="submit">
                              Register
                            </button>
                          )}
                          {error && <p>{error}</p>}
                         
                        </div>

                        <div className="col-12 order-md-3 mt-3 mt-md-5 fw-bold text-center text-md-start">
                          New user ? <Link to="/login">Login</Link>
                        </div>
                      </div>
                      <div>
          <ToastContainer theme="dark" />
        </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer>
        <ul className="fnav">
          <li>
            {/* <Link to="mailto:digiccard@gmail.com">Digiccard@gmail.com</Link> */}
            <a
              href="mailto:digiccard@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Digiccard@gmail.com
            </a>
          </li>
          <li >
                  <Link to="/terms_and_condition">
                  Terms & Condition
                  </Link>
                </li>
         
          {/* <li>
            <Link to="tel:+971542747073">Digiccard@gmail.com</Link>
            <a href="tel:+971542747073">Contact</a>
          </li> */}
        </ul>
        {/* <span className="powered">Powered by  
        <a href="" className='ms-2'>Adzoob Digital Solutions</a>
    </span> */}
      </footer>
    </div>
  );
};

export default RegisterForm;
