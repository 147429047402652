import Login from './components/login/login';
import { Route, Routes, Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Test } from './components/test';
import { UserDetails } from './components/view-user/user-details';
import { AddUser } from './components/view-user/add-user';
import store from './components/redux/store';
import Register from './components/login/register';
import HomePage from './components/home/home';
import TermsCondition from './components/login/termsCondition'
import Privacy from './components/login/privacy'

import ChangePassword from './components/login/change-password';
import Help from './components/login/help';


// const store = createStore(loginReducer, applyMiddleware(thunkMiddleware));

function App() {
  return (
    <Provider store={store}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/test" element={<Test />} />
        <Route path="/:id" element={<UserDetails />} />
        <Route path="/register" element={<Register />} />
        <Route path="/add-user" element={<AddUser />} />
  
        <Route path="/change-password" element={<ChangePassword />} />
        
        <Route path="/terms_and_condition" element={<TermsCondition />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/help/setup" element={<Help />} />
        
        {/* <Route path='/add-user' element={ <Test />} /> */}
      </Routes>
    </Provider>
  );
}

export default App;
