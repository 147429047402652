import axios from 'axios';
import { redirect, useNavigate } from 'react-router-dom';
import { ActionTypes } from './constants';
import { instance } from '../axios-api/axios-api';
export const loginRequest = () => ({
  type: ActionTypes.LOGIN_REQUEST,
});

export const loginSuccess = user => ({
  type: ActionTypes.LOGIN_SUCCESS,
  payload: {
    data: user.data,
    status: user.status,
    headers: user.headers.toJSON(), // serializable representation of headers
  },
});

export const loginFailure = error => ({
  type: ActionTypes.LOGIN_FAILURE,
  payload: error,
});

export const getUser = userData => {
  return {
    type: ActionTypes.SET_PRODUCTS,
    payload: userData,
  };
};



export const login = (email, password) => {
 

  return instance
    .post('/auth/login', { email, password })
    .then(response => {
     
      localStorage.setItem('jwtToken', response.data.access_token);
      return loginSuccess(response);
    })
    .catch(error => {
      // const err = error.response.data.message ?error.response.data.message : error.message;    
      const err = error?.response?.data?.message
      ? error?.response.data.message
      : error?.message;  
      return loginFailure(err);
    });
};

// export const getUserData = (id) => {

//   const instance = axios.create({
//     baseURL: 'http://localhost:3002',
//   });

//   return instance.get(`/user/${id}`)
//   .then((response) => {
//     return response
//   })
//   .catch((error) => {
//     return error
//   });

// };
