import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const HomePage = () => {
  const componentDidMount = () => {
    const script = document.createElement('script');
    script.src = '../../../assets/js/mdb.min.js';
    script.async = true;
    document.body.appendChild(script);
  };
  useEffect(() => {
    componentDidMount();
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <header>
        {/* <span className="logo">
        <img src="../assets/images/logo.svg" alt="logo"/>
    </span> */}

        <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-0">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <span className="logo mt-0">
                <img src="../assets/images/logo.svg" alt="logo" />
              </span>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link className="nav-link active" to="/register">
                    Register
                  </Link>
                  {/* <a className="nav-link active" aria-current="page" href="#">Home</a> */}
                </li>
                {/* <li className="nav-item">
        <Link className='nav-link' to="/about">About</Link>
        </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/login">
                    Login
                  </Link>
                </li>
              
                <li className="nav-item">
                <Link className="nav-link" to="https://wa.me/+971542747073">Contact Us</Link>
                  {/* <a className="nav-link" href="tel:+971542747073">Contact</a> */}
                </li>

                <li className="nav-item">
                <Link className="nav-link" to="/help/setup">Help</Link>
                  {/* <a className="nav-link" href="tel:+971542747073">Contact</a> */}
                </li>

               

               
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <section>
        <div className="col-md-12">
          {/* <!-- Carousel wrapper --> */}
          <div
            id="carouselBasicExample"
            className="carousel slide carousel-fade"
            data-mdb-ride="carousel"
          >
            {/* <!-- Inner --> */}
            <div className="carousel-inner">
              {/* <!-- Single item --> */}
              <div className="carousel-item active">
                <img
                  src="../assets/images/bg01.jpg"
                  className="d-block w-100"
                  alt="..."
                />
                <div className="carousel-caption">
                  <div className="inside_contents">
                    {/* <h1>Digic World <br/>
                    Lorem Ipsum is simply</h1>
                  <p>Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry for content.</p>
                  <button className="more">READ MORE</button> */}
                  </div>
                </div>
              </div>

              {/* <!-- Single item --> */}
              <div className="carousel-item">
                <img
                  src="../assets/images/bg02.jpg"
                  className="d-block w-100"
                  alt="..."
                />
                <div className="carousel-caption">
                  <div className="inside_contents">
                    {/* <h1>Digic World <br/>
                      Lorem Ipsum is simply</h1>
                    <p>Lorem Ipsum is simply dummy text of the
                      printing and typesetting industry for content.</p>
                    <button className="more">READ MORE</button> */}
                  </div>
                </div>
              </div>

              <div className="carousel-item">
                <img
                  src="../assets/images/bg01.jpg"
                  className="d-block w-100"
                  alt="..."
                />
                <div className="carousel-caption">
                  <div className="inside_contents">
                    {/* <h1>Digic World <br/>
                      Lorem Ipsum is simply</h1>
                    <p>Lorem Ipsum is simply dummy text of the
                      printing and typesetting industry for content.</p>
                    <button className="more">READ MORE</button> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Inner -->
    <!-- Indicators --> */}
            <div className="carousel-indicators">
              <button
                type="button"
                data-mdb-target="#carouselBasicExample"
                data-mdb-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-mdb-target="#carouselBasicExample"
                data-mdb-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-mdb-target="#carouselBasicExample"
                data-mdb-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            {/* <!-- Controls --> */}
            <button
              className="carousel-control-prev"
              type="button"
              data-mdb-target="#carouselBasicExample"
              data-mdb-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-mdb-target="#carouselBasicExample"
              data-mdb-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>

      <section>
        <div className="col-md-12 maincontainer">
          <div className="row m0">
            <div className="col-md-12">
              <h4>Have A Glance Of Our</h4>
              <h1>
                Digital Business <br />
                Card Features
              </h1>
            </div>
            <div className="clear"></div>
            <div className="col-md-6 box">
              <div>
                <img src="../assets/images/icon1.png" alt="" />
              </div>
              <h2>Interactive And User Focused</h2>
              <p>
              Personalized and interactive user interface (UI) designed to meet your specific needs. Our UI allows users to effortlessly save contacts directly to their phone, ensuring quick access to important information. Additionally, it facilitates seamless sharing, making it easy to share contacts with others
              </p>
            </div>
            <div className="col-md-6 box">
              <div>
                <img src="../assets/images/icon2.png" alt="" />
              </div>
              <h2>Intelligent Connectivity</h2>
              <p>
              Our product effortlessly works with NFC-enabled smartphones, whether they run on Android or iOS. You don't need any additional apps to use your Digic card, and updating your information is as easy as a single tap
              </p>
            </div>
            <div className="col-md-6 box">
              <div>
                <img src="../assets/images/icon3.png" alt="" />
              </div>
              <h2>Instant Activation</h2>
              <p>
              Digic business card boasts an instant activation feature, ensuring that it springs into action and engages recipients right from the moment of acquisition
              </p>
            </div>
            <div className="col-md-6 box">
              <div>
                <img src="../assets/images/icon4.png" alt="" />
              </div>
              <h2>Eco-friendly</h2>
              <p>
                Digic Card is a sustainable solution to traditional paper
                business cards. It is an effective way to network while also
                being mindful of the environment.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="col-md-12 maincontainer ptb0_prl100 mt-5">
          <div className="row">
            <div className="col-md-12">
              <h1 className="cw upper">Pricing</h1>
            </div>
            <div className="clear"></div>
            <div className="col-md-6 box box2">
              <div className="title">NORMAL</div>
              <div className="pvalue">
                140<span> AED</span>
              </div>
            </div>
            <div className="col-md-6 box box2">
              <div className="title">CUSTOM</div>
              <div className="pvalue">
               <span> Coming Soon...</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <ul className="fnav">
          <li>
            {/* <Link to="mailto:digiccard@gmail.com">Digiccard@gmail.com</Link> */}
            <a
              href="mailto:digiccard@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              digiccard@gmail.com
            </a>
          </li>
          <li >
                  <Link to="/terms_and_condition">
                  Terms & Condition
                  </Link>
                </li>
                <li >
                  <Link to="/privacy">
                  Privacy Policy
                  </Link>
                </li>
         
          {/* <li>
            <Link to="tel:+971542747073">Digiccard@gmail.com</Link>
            <a href="tel:+971542747073">Contact</a>
          </li> */}
        </ul>
        {/* <span className="powered">Powered by  
        <a href="" className='ms-2'>Adzoob Digital Solutions</a>
    </span> */}
      </footer>
    </div>
  );
};

export default HomePage;
