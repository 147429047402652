import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Privacy = () => {
  return (
    <div>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <span className="logo mt-0">
                <img src="../assets/images/logo.svg" alt="logo" />
              </span>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link className="nav-link active" to="/">
                    Home
                  </Link>
                  {/* <a className="nav-link active" aria-current="page" href="#">Home</a> */}
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/login">
                    Login
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <div className=" maincontainer">
        <header className="bg-transparent">
          <h1>Privacy Policy</h1>
        </header>
        <div>
          <h3>Introduction</h3>
          <p>&nbsp;</p>
          <p>Last updated: May 13, 2023</p>

          <p>
            Thank you for using DIGIC, our NFC digital business card service.
            This Privacy Policy outlines how we collect, use, and protect your
            personal information when you access and interact with our website..
          </p>
        </div>
        <div>
          <h3 className="mt-5">Information We Collect</h3>
          {/* <p>
            When you access and use our website, we may collect certain
            information from you, including:
          </p> */}
          <ul className="left-list">
          <p>
            When you access and use our website, we may collect certain
            information from you, including:
          </p>
            <li>
              Personal information such as your name, email address, and phone
              number, which you provide when creating an account or contacting
              us.
            </li>
            <li>
              Information about your device, such as your IP address, browser
              type, and operating system, which is automatically collected when
              you visit our website.
            </li>
            <li>
              Usage information, such as the pages you visit and the actions you
              take on our website.
            </li>
          </ul>
          <h3 className="mt-5">How We Use Your Information</h3>
         
          <ul className="left-list">
          <p>
            We use the information we collect for various purposes, including:
          </p>
            <li>
              Providing and personalizing our services to meet your needs.
            </li>
            <li>
              Allowing users to access and view business profiles by tapping,
              scanning QR codes, or using direct links.
            </li>
            <li>Improving our website and enhancing user experience.</li>
            <li>
              Communicating with you, responding to your inquiries, and
              providing customer support.
            </li>
          </ul>

          <div className="col-md-12">
            <h3 className="mt-5">Sharing Your Information</h3>
           
            <ul className="left-list">
            <p>
              We may share your personal information with third parties in the
              following circumstances:
            </p>
              <li>With your consent or at your direction.</li>
              <li>
                To comply with legal obligations, such as responding to lawful
                requests and legal process.
              </li>
              <li>
                To enforce our website policies or protect our rights, property,
                or safety.
              </li>
            </ul>
          </div>

          <h3 className="mt-5">Third-Party Links</h3>
          <p>Our website may contain links to third-party websites or services. These third-party sites have separate and independent privacy policies, and we have no control over and assume no responsibility for their content, privacy policies, or practices. We encourage you to review the privacy policies of those third-party sites before providing any personal information.</p>

          <h3 className="mt-5">Updates to This Privacy Policy</h3>
          <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated policy on our website.</p>
          <h3 className="mt-5">Contact Us</h3>
          <p>If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us at digiccard@gmail.com</p>
        </div>
      </div>
      <footer className="mt-5">
        <ul className="fnav">
          <li>
            {/* <Link to="mailto:digiccard@gmail.com">Digiccard@gmail.com</Link> */}
            <a
              href="mailto:digiccard@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Digiccard@gmail.com
            </a>
          </li>
          <li>
            {/* <Link to="tel:+971542747073">Digiccard@gmail.com</Link> */}
            <a href="tel:+971542747073">Contact</a>
          </li>
        </ul>
        {/* <span className="powered">Powered by 
        <a href="" className='ms-2'>Adzoob Digital Solutions</a>
    </span> */}
      </footer>
    </div>
  );
};
export default Privacy;
