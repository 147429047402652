import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";

const TermsCondition = () => {
return (<div>
    <header>
       

<nav className="navbar navbar-expand-lg navbar-light bg-transparent">
  <div className="container-fluid">
    <a className="navbar-brand" href="#">
    <span className="logo mt-0">
        <img src="../assets/images/logo.svg" alt="logo"/>
    </span>
    </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav ms-auto">
        <li className="nav-item">
        <Link className='nav-link active' to="/">Home</Link>
          {/* <a className="nav-link active" aria-current="page" href="#">Home</a> */}
        </li>
      
        <li className="nav-item">
          <Link className="nav-link" to="/login">Login</Link>
        </li>
        
      </ul>
    </div>
  </div>
</nav>

</header>


 <div className=" maincontainer">
	<header className="bg-transparent">
		<h1>Terms and Conditions</h1>
	</header>
	<div>
		<h3>Introduction</h3>
        <p>&nbsp;</p>
		<p>Last updated: May 13, 2023</p>

<p>Please read these Terms of Use (“Terms”, “Terms of Use”) carefully before using the https://www.digiccard.com  website (the “Service”) operated by DIGIC (“us”, “we”, or “our”).

Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.

By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>
</div>
<div>
        <h3 className="mt-5">Intellectual Property Rights</h3>
		<p>The Service and its original content, features and functionality are and will remain the exclusive property of DIGIC and its licensors.</p>
		<h3 className="mt-5">Links To Other Web Sites</h3>
		<p>
Our Service may contain links to third-party web sites or services that are not owned or controlled by DIGIC.

DIGIC has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that DIGIC  shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.

We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.

</p>

<div className="col-md-12">
                   
		<h3 className="mt-5">Disclaimer</h3>
		<p>Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>
		</div>
       
        <h3 className="mt-5">Changes</h3>
		<p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.

By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>
	
    <h3 className="mt-5">Card Renewal</h3>
		<p>The card will expire in every 2 years from the date of purchase. However, upon expiration, you have the option to renew your subscription by paying a renewal fee. The specific details of the renewal fee will be discussed and communicated at the time of card purchase. Renewal will provide you with continued access to the service, including any updated features that may be introduced during the subscription period. .</p>
        <h3 className="mt-5">Contact Us</h3>
		<p>If you have any questions about these Terms, please contact us.</p>
        </div>

</div>
<footer className="mt-5">
<ul className="fnav">
          <li>
            {/* <Link to="mailto:digiccard@gmail.com">Digiccard@gmail.com</Link> */}
            <a
              href="mailto:digiccard@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Digiccard@gmail.com
            </a>
          </li>
          <li>
            {/* <Link to="tel:+971542747073">Digiccard@gmail.com</Link> */}
            <a href="tel:+971542747073">Contact</a>
          </li>
        </ul>
    {/* <span className="powered">Powered by 
        <a href="" className='ms-2'>Adzoob Digital Solutions</a>
    </span> */}
</footer>

</div>)

}
export default TermsCondition;