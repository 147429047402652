import { ActionTypes } from './constants';

const initialState = {
  user: null,
  userData: null,
  isLoading: false,
  error: null,
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_REQUEST:
      return { ...state, isLoading: true, error: null };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.data,
        isLoading: false,
        error: null,
      };
    case ActionTypes.LOGIN_FAILURE:
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
};

export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_REQUEST:
      return { ...state, isLoading: true, error: null };
    case ActionTypes.SET_USER_DETAILS:
      return {
        ...state,
        userData: action.payload,
        isLoading: false,
        error: null,
      };
    case ActionTypes.LOGIN_FAILURE:
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
};
