import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { instance } from '../axios-api/axios-api';
import { ClipLoader, HashLoader } from 'react-spinners';
import { Buffer } from 'buffer';
import { Helmet } from 'react-helmet';

export function UserDetails() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageBase64String, setImageBase64String] = useState(null);
  const [userDataFiltered, setData] = useState({});
  const [userDetails, setUserDetails] = useState({
    designation: '',
    company: '',
    username: '',
  });
  const [userContact, setUserContact] = useState({
    name: '',
    phone: '',
    email: '',
  });

  useEffect(() => {
    setIsLoading(true);
    instance
      .get(`/user/get-user/${id}`)
      .then(response => {
        setIsLoading(false);
        const dataArray = response.data.dataArray
          ? response.data.dataArray
          : [];
        const userDetails = response.data.userDetails
          ? response.data.userDetails
          : [];

        let imageArray = response.data ? response.data.image : {};

        if (imageArray && imageArray.id) {
          const imageUn = new Uint8Array(imageArray.data.data);
          setImageBase64String(imageUn);
          const blob = new Blob([imageUn.buffer], { type: 'image/jpeg' });
          const imageUrl = URL.createObjectURL(blob);
          setImageUrl(imageUrl);
        }

        setUserDetails({
          company: userDetails?.company ? userDetails?.company : '',
          username: userDetails?.username ? userDetails?.username : '',
          designation: userDetails?.designation ? userDetails?.designation : '',
        });
        if (dataArray && dataArray.length > 0) {
          const obj = dataArray.reduce((acc, curr) => {
            acc[curr.name] = curr;
            return acc;
          }, {});

          setData(obj);

          setUserContact({
            youtube: obj.youtube ? obj.youtube.value : null,
            instagram: obj.instagram ? obj.instagram.value : null,
            linkedin: obj.linkedin ? obj.linkedin.value : null,
            email: obj.mail ? obj.mail.value : null,
            phone: obj.phone ? obj.phone.value : null,
            name: userDetails ? userDetails.username : null,
            id: id,
          });
        }
      })
      .catch(error => {
        setIsLoading(false);
        const err = error?.response?.data?.message
          ? error?.response.data.message
          : error?.message;
        toast.error(err);
      });
  }, [id]);

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleSave = async () => {
    let base64Image;
    if (imageBase64String) {
      const buffer = Buffer.from(imageBase64String);

      // Convert Buffer to base64 string
      base64Image = buffer.toString('base64');
    } else {
      base64Image = '';
    }

    var contact = userContact;

    var vcard =
      'BEGIN:VCARD\n' +
      'VERSION:3.0\n' +
      'FN:' +
      contact.name +
      '\n' +
      'TEL;TYPE=work,voice:' +
      contact.phone +
      '\n' +
      'EMAIL:' +
      contact.email +
      '\n' +
      'PHOTO;TYPE=JPEG;ENCODING=B:' +
      base64Image +
      '\n' +
      'URL;TYPE=LINKEDIN:https://www.linkedin.com/in/' +
      contact.linkedin +
      '\n' +
      'URL;TYPE=WHATSAPP:https://wa.me/' +
      contact.phone +
      '\n' +
      'URL;TYPE=INSTAGRAM:https://www.instagram.com/' +
      contact.instagram +
      '\n' +
      'URL;TYPE=YOUTUBE:' +
      contact.youtube +
      '\n' +
      'URL;TYPE=DIGIC:https://www.digiccard.com/' +
      id +
      '\n' +
      'END:VCARD';

    var blob = new Blob([vcard], { type: 'text/vcard' });
    var url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.download = `${contact.name}.vcf`;
    link.href = url;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="allstyle">
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <HashLoader color="#b8846d" />
        </div>
      ) : (
        <div>
          {userDataFiltered && Object.keys(userDataFiltered).length > 0 ? (
            <div>
              <Helmet>
                <meta
                  property="og:title"
                  content={capitalizeFirstLetter(userDetails.username)}
                />
                {/* <meta
              property="og:description"
              content="Description of Your Page"
            /> */}
                <meta property="og:image" content={imageUrl} />
                <meta
                  property="og:url"
                  content={'https://www.digiccard.com/' + id}
                />
              </Helmet>
              <section>
                {/* <span className="logo logo-margin">
                <img src="../assets/images/logo.svg" />
              </span> */}
                <div className="profile_con mt-5">
                  <span className="profile">
                    <span className="pic">
                      <img
                        src={imageUrl ? imageUrl : '../assets/images/user.png'}
                        alt=""
                        className="h-full img-fluid"
                      />
                    </span>
                  </span>
                  <h1 className="mb-2">
                    {capitalizeFirstLetter(userDetails.username)}
                  </h1>
                  <h5>
                    <span className="text-gray fw-lightery">
                      {' '}
                      {capitalizeFirstLetter(userDetails.designation)}
                    </span>
                    <span className="d-block fw-bold mt-2">
                      {capitalizeFirstLetter(userDetails.company)}
                    </span>
                  </h5>
                  <div className="divider divider-width">
                    <span></span>
                  </div>
                  <h4 className="mt-5">Click Below Icons to Contact</h4>
                </div>
                <div className="icons icon-width pt-0">
                  <ul id="myList">
                    <li
                      className={
                        userDataFiltered.whatsapp &&
                        userDataFiltered.whatsapp.active === true
                          ? 'active'
                          : ''
                      }
                      value="whatsapp"
                    >
                      <a
                        href={
                          userDataFiltered.whatsapp
                            ? `https://wa.me/${userDataFiltered.whatsapp.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/icons/24whatsapp.svg"
                          alt=""
                          className="h-full"
                        />
                        <div className="name">Whatsapp</div>
                      </a>
                    </li>

                    <li
                      className={
                        userDataFiltered.phone &&
                        userDataFiltered.phone.active === true
                          ? 'active'
                          : ''
                      }
                      value="phone"
                    >
                      <a
                        href={
                          userDataFiltered.phone
                            ? `tel:${userDataFiltered.phone.value}`
                            : '#'
                        }
                      >
                        <img
                          src="../assets/images/icons/2call.svg"
                          alt=""
                          className="h-full"
                        />
                        <div className="name">Phone</div>
                      </a>
                    </li>

                    <li
                      className={
                        userDataFiltered.mail &&
                        userDataFiltered.mail.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.mail
                            ? `mailto:${userDataFiltered.mail.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/icons/mail.svg"
                          alt=""
                          className="h-full"
                        />
                        <div className="name">Mail</div>
                      </a>
                    </li>

                    {/* linkedin */}

                    <li
                      className={
                        userDataFiltered.linkedin &&
                        userDataFiltered.linkedin.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.linkedin
                            ? `https://www.linkedin.com/in/${userDataFiltered.linkedin.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/icons/20linkedin.svg"
                          alt=""
                        />
                        <div className="name">Linkedin</div>
                      </a>
                    </li>

                    {/* twitter */}
                    <li
                      className={
                        userDataFiltered.twitter &&
                        userDataFiltered.twitter.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.twitter
                            ? `https://twitter.com/${userDataFiltered.twitter.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/icons/13twitter.svg"
                          alt=""
                        />
                        <div className="name">Twitter</div>
                      </a>
                    </li>

                    {/* facebook */}
                    <li
                      className={
                        userDataFiltered.facebook &&
                        userDataFiltered.facebook.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.facebook
                            ? `https://www.facebook.com/${userDataFiltered.facebook.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="h-full"
                          src="../assets/images/icons/8fb.svg"
                          alt=""
                        />
                        <div className="name">Facebook</div>
                      </a>
                    </li>
                    {/* website */}
                    <li
                      className={
                        userDataFiltered.website &&
                        userDataFiltered.website.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.website
                            ? `${userDataFiltered.website.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className=""
                          src="../assets/images/icons/website.svg"
                          alt=""
                        />
                        <div className="name">Website</div>
                      </a>
                    </li>

                    {/* youtube */}
                    <li
                      className={
                        userDataFiltered.youtube &&
                        userDataFiltered.youtube.active === true
                          ? 'active'
                          : ''
                      }
                      value="youtube"
                    >
                      <a
                        href={
                          userDataFiltered.youtube
                            ? userDataFiltered.youtube.value
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/icons/1youtube.svg"
                          alt=""
                          className="h-full"
                        />
                        <div className="name">Youtube</div>
                      </a>
                    </li>

                    {/* spotify */}
                    <li
                      className={
                        userDataFiltered.spotify &&
                        userDataFiltered.spotify.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.spotify
                            ? `https://open.spotify.com/track/${userDataFiltered.spotify.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/icons/5spotify.svg"
                          alt=""
                          className="h-full"
                        />
                        <div className="name">Spotify</div>
                      </a>
                    </li>

                    {/* telegram */}
                    <li
                      className={
                        userDataFiltered.telegram &&
                        userDataFiltered.telegram.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.telegram
                            ? `https://t.me/${userDataFiltered.telegram.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="../assets/images/icons/telegram.svg" alt="" />
                        <div className="name">Telegram</div>
                      </a>
                    </li>

                    {/* instagram  */}
                    <li
                      className={
                        userDataFiltered.instagram &&
                        userDataFiltered.instagram.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.instagram
                            ? `https://www.instagram.com/${userDataFiltered.instagram.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/icons/11instagram.svg"
                          alt=""
                        />
                        <div className="name">Instagram</div>
                      </a>
                    </li>

                    {/* skype */}
                    <li
                      className={
                        userDataFiltered.skype &&
                        userDataFiltered.skype.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.skype
                            ? `skype:${userDataFiltered.skype.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="../assets/images/icons/22skype.svg" alt="" />
                        <div className="name">Skype</div>
                      </a>
                    </li>

                    {/* location */}
                    <li
                      className={
                        userDataFiltered.location &&
                        userDataFiltered.location.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.location
                            ? `https://www.google.com/maps?q=${userDataFiltered.location.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className=""
                          src="../assets/images/icons/location.svg"
                          alt=""
                        />
                        <div className="name">Location</div>
                      </a>
                    </li>

                    <li
                      className={
                        userDataFiltered.threads &&
                        userDataFiltered.threads.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.threads
                            ? `https://www.threads.net/${userDataFiltered.threads.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="../assets/images/icons/threads.svg" alt="" />
                        <div className="name">Threads</div>
                      </a>
                    </li>

                    {/* pinterest */}
                    <li
                      className={
                        userDataFiltered.pinterest &&
                        userDataFiltered.pinterest.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.pinterest
                            ? `https://www.pinterest.com/${userDataFiltered.pinterest.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="fb h-full"
                          src="../assets/images/icons/6pinterest.svg"
                          alt=""
                        />
                        <div className="name">Pinterest</div>
                      </a>
                    </li>
                    {/* dribble */}
                    <li
                      className={
                        userDataFiltered.dribble &&
                        userDataFiltered.dribble.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.dribble
                            ? `https://dribbble.com/${userDataFiltered.dribble.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/icons/3dribbble.svg"
                          alt=""
                          className="h-full"
                        />
                        <div className="name">Dribble</div>
                      </a>
                    </li>
                    {/* Vimeo */}
                    <li
                      className={
                        userDataFiltered.vimeo &&
                        userDataFiltered.vimeo.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.vimeo
                            ? `https://vimeo.com/${userDataFiltered.vimeo.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/icons/4vimeo.svg"
                          alt=""
                          className="h-full"
                        />
                        <div className="name">Vimeo</div>
                      </a>
                    </li>

                    {/* rss */}
                    <li
                    // className={
                    //   userDataFiltered.rss &&
                    //   userDataFiltered.rss.active === true
                    //     ? 'active'
                    //     : ''
                    // }
                    >
                      <a
                        href={
                          userDataFiltered.rss
                            ? `${userDataFiltered.rss.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/icons/15rss.svg"
                          alt=""
                          className="h-full"
                        />
                        <div className="name">RSS</div>
                      </a>
                    </li>

                    {/* tiktok */}
                    <li
                      className={
                        userDataFiltered.tiktok &&
                        userDataFiltered.tiktok.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.tiktok
                            ? `tiktok://user?id=${userDataFiltered.tiktok.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className=""
                          src="../assets/images/icons/9tiktok.svg"
                          alt=""
                        />
                        <div className="name">Tiktok</div>
                      </a>
                    </li>
                    {/* soundcloud */}
                    <li
                    // className={
                    //   userDataFiltered.soundcloud &&
                    //   userDataFiltered.soundcloud.active === true
                    //     ? 'active'
                    //     : ''
                    // }
                    >
                      <a
                        href={
                          userDataFiltered.soundcloud
                            ? `${userDataFiltered.soundcloud.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/icons/9soundcloud.svg"
                          alt=""
                        />
                        <div className="name">Soundcloud</div>
                      </a>
                    </li>
                    {/* warwick */}
                    <li
                    // className={
                    //   userDataFiltered.warwick &&
                    //   userDataFiltered.warwick.active === true
                    //     ? 'active'
                    //     : ''
                    // }
                    >
                      <a
                        href={
                          userDataFiltered.warwick
                            ? `${userDataFiltered.warwick.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/icons/10warwick.svg"
                          alt=""
                        />
                        <div className="name">Warwick</div>
                      </a>
                    </li>

                    {/* paypal */}
                    <li
                    // className={
                    //   userDataFiltered.paypal &&
                    //   userDataFiltered.paypal.active === true
                    //     ? 'active'
                    //     : ''
                    // }
                    >
                      <a
                        href={
                          userDataFiltered.paypal
                            ? `https://www.paypal.com/paypalme/${userDataFiltered.paypal.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="../assets/images/icons/23paypal.svg" alt="" />
                        <div className="name">Paypal</div>
                      </a>
                    </li>

                    {/* dropbox */}

                    <li
                      className={
                        userDataFiltered.dropbox &&
                        userDataFiltered.dropbox.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.dropbox
                            ? `${userDataFiltered.dropbox.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/icons/21dropbox.svg"
                          alt=""
                        />
                        <div className="name">Dropbox</div>
                      </a>
                    </li>

                    <li
                    // className={
                    //   userDataFiltered.cute && userDataFiltered.cute.active === true
                    //     ? 'active'
                    //     : ''
                    // }
                    >
                      <a
                        href={
                          userDataFiltered.cute
                            ? `${userDataFiltered.cute.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className=""
                          src="../assets/images/icons/12cute.svg"
                          alt=""
                        />
                        <div className="name">Cute</div>
                      </a>
                    </li>
                    {/* snapchat */}
                    <li
                      className={
                        userDataFiltered.snapchat &&
                        userDataFiltered.snapchat.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.snapchat
                            ? `https://www.snapchat.com/add/${userDataFiltered.snapchat.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/icons/14snapchat.svg"
                          alt=""
                        />
                        <div className="name">Snapchat</div>
                      </a>
                    </li>

                    {/* tumblr */}
                    <li
                    // className={
                    //   userDataFiltered.tumblr &&
                    //   userDataFiltered.tumblr.active === true
                    //     ? 'active'
                    //     : ''
                    // }
                    >
                      <a
                        href={
                          userDataFiltered.tumblr
                            ? `https://${userDataFiltered.vimeo.value}.tumblr.com`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="fb"
                          src="../assets/images/icons/18tumblr.svg"
                          alt=""
                        />
                        <div className="name">Tumblr</div>
                      </a>
                    </li>

                    {/* behance */}

                    <li
                      className={
                        userDataFiltered.behance &&
                        userDataFiltered.behance.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.behance
                            ? `https://www.behance.net/${userDataFiltered.behance.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/icons/19behance.svg"
                          alt=""
                        />
                        <div className="name">Behance</div>
                      </a>
                    </li>

                    {/* flickr */}
                    <li
                      className={
                        userDataFiltered.flickr &&
                        userDataFiltered.flickr.active === true
                          ? 'active'
                          : ''
                      }
                    >
                      <a
                        href={
                          userDataFiltered.flickr
                            ? `${userDataFiltered.flickr.value}`
                            : '#'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="../assets/images/icons/25flickr.svg" alt="" />
                        <div className="name">Flickr</div>
                      </a>
                    </li>
                  </ul>
                  {/* <div className="button_con">
              <button id="loadMore">LOAD MORE</button>
            </div> */}
                </div>
                <ul className="btm_btn exchange-btn">
                  <li className="w-100">
                    {/* <li > */}
                    <button onClick={handleSave}>SAVE CONTACT</button>
                  </li>
                  {/* <li>
              <button onClick={handlsShare}>EXCHANGE</button>
            </li> */}
                </ul>
              </section>
            </div>
          ) : (
            <div>
              <h1>Hello! Welcome To Digic,The Given User Was Not Found</h1>
            </div>
          )}
          <div>
            <ToastContainer theme="dark" />
          </div>
        </div>
      )}
    </div>
  );
}
