import React, { useState, useEffect } from 'react';
import './add-user.css';
import { instance } from '../axios-api/axios-api';
import { Link, useNavigate } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import '../css/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function AddUser(id) {
  const [userDataDetails, setUserDataDetails] = useState({
    username: '',
    designation: '',
    company: '',
  });

  const [imageUrl, setImageUrl] = useState(null);
  const [imageUrlFormdata, setImageUrlFormdata] = useState(null);

  const navigate = useNavigate();
  const jwtToken = localStorage.getItem('jwtToken');

  const [formValues, setFormValues] = useState({
    youtube: '',
    phone: '',
    dribble: '',
    whatsapp: '',
    mail: '',
    spotify: '',
    pinterest: '',
    android: '',
    twitter: '',
    linkedin: '',
    instagram: '',
    facebook: '',
    website: '',
    location: '',
    tiktok: '',
    soundcloud: '',
    warwick: '',
    telegram: '',
    snapchat: '',
    cute: '',
    threads: '',
    behance: '',
    tumblr: '',
    dropbox: '',
    skype: '',
    paypal: '',
    flickr: '',
    rss: '',
    vimeo: '',
  });

  const [checkBoxValues, setCheckBoxValues] = useState({
    youtube: false,
    phone: false,
    dribble: false,
    whatsapp: false,
    mail: false,
    spotify: false,
    pinterest: false,
    android: false,
    twitter: false,
    linkedin: false,
    instagram: false,
    facebook: false,
    website: false,
    location: false,
    tiktok: false,
    soundcloud: false,
    warwick: false,
    telegram: false,
    snapchat: false,
    cute: false,
    threads: false,
    behance: false,
    tumblr: false,
    dropbox: false,
    skype: false,
    paypal: false,
    flickr: false,
    rss: false,
    vimeo: false,
  });

  useEffect(() => {
    console.log(22);
    if (jwtToken) {
      instance.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken;
      instance.defaults.headers.post['Content-Type'] = 'application/json';
      instance
        .get('/user/current-user')
        .then(response => {
          let dataArray = response.data ? response.data.dataArray : [];

          let userData = response.data ? response.data.user : [];

          let imageArray = response.data ? response.data.image : {};

          if (imageArray && imageArray.id) {
            const t = new Uint8Array(imageArray.data.data);
            const blob = new Blob([t.buffer], { type: 'image/jpeg' });
            const imageUrl = URL.createObjectURL(blob);
            setImageUrl(imageUrl);
          }
          if (userData) {
            setUserDataDetails(userData);
          }

          if (dataArray && dataArray.length > 0) {
            const obj = dataArray.reduce((acc, curr) => {
              acc[curr.name] = curr;
              return acc;
            }, {});

            setFormValues({
              youtube: obj.youtube ? obj.youtube.value : null,
              phone: obj.phone ? obj.phone.value : null,
              dribble: obj.dribble ? obj.dribble.value : null,
              whatsapp: obj.whatsapp ? obj.whatsapp.value : null,
              mail: obj.mail ? obj.mail.value : null,
              spotify: obj.spotify ? obj.spotify.value : null,
              pinterest: obj.pinterest ? obj.pinterest.value : null,
              android: obj.android ? obj.android.value : null,
              twitter: obj.twitter ? obj.twitter.value : null,
              linkedin: obj.linkedin ? obj.linkedin.value : null,
              instagram: obj.instagram ? obj.instagram.value : null,
              facebook: obj.facebook ? obj.facebook.value : null,
              website: obj.website ? obj.website.value : null,
              location: obj.location ? obj.location.value : null,
              tiktok: obj.tiktok ? obj.tiktok.value : null,
              soundcloud: obj.soundcloud ? obj.soundcloud.value : null,
              warwick: obj.warwick ? obj.warwick.value : null,
              telegram: obj.telegram ? obj.telegram.value : null,
              snapchat: obj.snapchat ? obj.snapchat.value : null,
              cute: obj.cute ? obj.cute.value : null,
              threads: obj.threads
                ? obj.threads.value
                : null,
              behance: obj.behance ? obj.behance.value : null,
              tumblr: obj.tumblr ? obj.tumblr.value : null,
              dropbox: obj.dropbox ? obj.dropbox.value : null,
              skype: obj.skype ? obj.skype.value : null,
              paypal: obj.paypal ? obj.paypal.value : null,
              flickr: obj.flickr ? obj.flickr.value : null,
              rss: obj.rss ? obj.rss.value : null,
              vimeo: obj.vimeo ? obj.vimeo.value : null,
            });
            setCheckBoxValues({
              youtube: obj.youtube ? obj.youtube.active : false,
              phone: obj.phone ? obj.phone.active : false,
              dribble: obj.dribble ? obj.dribble.active : false,
              whatsapp: obj.whatsapp ? obj.whatsapp.active : false,
              mail: obj.mail ? obj.mail.active : false,
              spotify: obj.spotify ? obj.spotify.active : false,
              pinterest: obj.pinterest ? obj.pinterest.active : false,
              android: obj.android ? obj.android.active : false,
              twitter: obj.twitter ? obj.twitter.active : false,
              linkedin: obj.linkedin ? obj.linkedin.active : false,
              instagram: obj.instagram ? obj.instagram.active : false,
              facebook: obj.facebook ? obj.facebook.active : false,
              website: obj.website ? obj.website.active : false,
              location: obj.location ? obj.location.active : false,
              tiktok: obj.tiktok ? obj.tiktok.active : false,
              soundcloud: obj.soundcloud ? obj.soundcloud.active : false,
              warwick: obj.warwick ? obj.warwick.active : false,
              telegram: obj.telegram ? obj.telegram.active : false,
              snapchat: obj.snapchat ? obj.snapchat.active : false,
              cute: obj.cute ? obj.cute.active : false,
              threads: obj.threads
                ? obj.threads.active
                : false,
              behance: obj.behance ? obj.behance.active : false,
              tumblr: obj.tumblr ? obj.tumblr.active : false,
              dropbox: obj.dropbox ? obj.dropbox.active : false,
              skype: obj.skype ? obj.skype.active : false,
              paypal: obj.paypal ? obj.paypal.active : false,
              flickr: obj.flickr ? obj.flickr.active : false,
              rss: obj.rss ? obj.rss.active : false,
              vimeo: obj.vimeo ? obj.vimeo.active : false,
            });
          }
        })
        .catch(error => {
          const err = error?.response?.data?.message
            ? error?.response.data.message
            : error?.message;
          toast.error(err);

          const statusCode = error?.response?.data?.statusCode
            ? error.response.data.statusCode
            : error.message;

          if (statusCode == 401) {
            setTimeout(() => {
              logout();
            }, 3000);
          }
        });
    }
  }, [jwtToken]);

  const handleInputChange = event => {
    const { id, value } = event.target;

    setFormValues({ ...formValues, [id]: value });
  };

  const userDataDetailsHandler = event => {
    const { id, value } = event.target;

    setUserDataDetails({ ...userDataDetails, [id]: value });
  };

  const handleCheckBoxChange = event => {
    const { id } = event.target;

    const val = !checkBoxValues[id];

    setCheckBoxValues({ ...checkBoxValues, [id]: val });
  };

  const handleSaveContact = () => {
    let isValid = true;
    const userDataRequest = [
      {
        name: 'youtube',
        value: formValues.youtube,
        active: checkBoxValues.youtube,
      },
      { name: 'phone', value: formValues.phone, active: checkBoxValues.phone },
      {
        name: 'dribble',
        value: formValues.dribble,
        active: checkBoxValues.dribble,
      },
      {
        name: 'whatsapp',
        value: formValues.whatsapp,
        active: checkBoxValues.whatsapp,
      },
      { name: 'mail', value: formValues.mail, active: checkBoxValues.mail },
      {
        name: 'spotify',
        value: formValues.spotify,
        active: checkBoxValues.spotify,
      },
      {
        name: 'pinterest',
        value: formValues.pinterest,
        active: checkBoxValues.pinterest,
      },
      {
        name: 'android',
        value: formValues.android,
        active: checkBoxValues.android,
      },

      {
        name: 'twitter',
        value: formValues.twitter,
        active: checkBoxValues.twitter,
      },
      {
        name: 'linkedin',
        value: formValues.linkedin,
        active: checkBoxValues.linkedin,
      },
      {
        name: 'instagram',
        value: formValues.instagram,
        active: checkBoxValues.instagram,
      },
      {
        name: 'facebook',
        value: formValues.facebook,
        active: checkBoxValues.facebook,
      },

      {
        name: 'website',
        value: formValues.website,
        active: checkBoxValues.website,
      },
      {
        name: 'location',
        value: formValues.location,
        active: checkBoxValues.location,
      },
      {
        name: 'tiktok',
        value: formValues.tiktok,
        active: checkBoxValues.tiktok,
      },
      {
        name: 'soundcloud',
        value: formValues.soundcloud,
        active: checkBoxValues.soundcloud,
      },

      {
        name: 'warwick',
        value: formValues.warwick,
        active: checkBoxValues.warwick,
      },
      {
        name: 'telegram',
        value: formValues.telegram,
        active: checkBoxValues.telegram,
      },
      {
        name: 'snapchat',
        value: formValues.snapchat,
        active: checkBoxValues.snapchat,
      },
      { name: 'cute', value: formValues.cute, active: checkBoxValues.cute },

      {
        name: 'threads',
        value: formValues.threads,
        active: checkBoxValues.threads,
      },
      {
        name: 'behance',
        value: formValues.behance,
        active: checkBoxValues.behance,
      },
      {
        name: 'tumblr',
        value: formValues.tumblr,
        active: checkBoxValues.tumblr,
      },
      {
        name: 'dropbox',
        value: formValues.dropbox,
        active: checkBoxValues.dropbox,
      },

      { name: 'skype', value: formValues.skype, active: checkBoxValues.skype },
      {
        name: 'paypal',
        value: formValues.paypal,
        active: checkBoxValues.paypal,
      },
      {
        name: 'flickr',
        value: formValues.flickr,
        active: checkBoxValues.flickr,
      },
      {
        name: 'vimeo',
        value: formValues.vimeo,
        active: checkBoxValues.vimeo,
      },
      { name: 'rss', value: formValues.rss, active: checkBoxValues.rss },
      // ... and so on for other input fields
    ];

    if (!userDataDetails.username) {
      isValid = false;
      toast.error('Name is mandatory');
    }

    const requestBody = {
      username: userDataDetails.username,
      designation: userDataDetails.designation
        ? userDataDetails.designation
        : '',
      company: userDataDetails.company ? userDataDetails.company : '',
      data: userDataRequest,
    };

    if (isValid) {
      instance.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken;
      instance.defaults.headers.post['Content-Type'] = 'application/json';
      instance
        .post('/user/store-user-data', requestBody)
        .then(response => {
          toast.success(response.statusText);
        })
        .catch(error => {
          const err = error?.response?.data?.message
            ? error?.response.data.message
            : error?.message;
          toast.error(err);
        });
    }
  };

  const logout = () => {
    // Remove the JWT token from local storage
    localStorage.removeItem('jwtToken');

    // Redirect the user to the login page
    navigate('/login');
  };

  // Helper function to convert a data URI to a Blob object

  const onChangeImage = async file => {
    const formData = new FormData();
    if (file) {
      setImageUrl(URL.createObjectURL(file));

      formData.append('file', file);
      instance.defaults.headers.post['Content-Type'] = 'multipart/form-data';
      instance
        .post('/user/upload', formData, {
          responseType: 'arraybuffer',
        })
        .then(response => {
          if (response) {
            const blob = new Blob([response.data], { type: 'image/jpeg' });
            const imageUrl = URL.createObjectURL(blob);
            setImageUrl(imageUrl);
          }
        })
        .catch(error => {
          const err = error?.response?.data?.message
            ? error?.response.data.message
            : error?.message;
          toast.error(err);
        });
    }
  };

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(imageUrl);
    };
  }, [imageUrl]);

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  // useEffect(()=>{

  // },[imageUrlFormdata])
  return (
    <div>
      <header>
        {/* <span className="logo">
        <img src="../assets/images/logo.svg" alt="logo"/>
    </span> */}

        <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-0">
          <div className="container-fluid">
            <Link to="/">
              <span className="logo mt-0">
                <img src="../assets/images/logo.svg" alt="logo" />
              </span>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link className="nav-link active" to="/">
                    Home
                  </Link>
                  {/* <a className="nav-link active" aria-current="page" href="#">Home</a> */}
                </li>
                {/* <li className="nav-item">
        <Link className='nav-link' to="/about">About</Link>
        </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/change-password">
                    Change Password
                  </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link" to="tel:+971542747073">Contact</Link>
                  {/* <a className="nav-link" href="tel:+971542747073">Contact</a> */}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <section>
        {/* <Link to="/">
          <span className="logo">
            <img src="../assets/images/logo.svg" />
          </span>
        </Link> */}
        <div className="profile_con mt-5">
          <span className="profile">
            <span className="pic">
              <img
                className="img-fluid"
                src={imageUrl ? imageUrl : '../assets/images/user.png'}
                alt=""
              />
            </span>
          </span>

          <h1 className="User_id">
            {userDataDetails.user_id ? userDataDetails.user_id : ''}
          </h1>

          <div className="divider new-divider ">
            <span></span>
          </div>
          <ImgCrop rotationSlider className="image-preview">
            <Upload
              beforeUpload={file => {
                console.log('this is file', file, URL.createObjectURL(file));
                // setImageUrl(URL.createObjectURL(file));
                onChangeImage(file);
                // setImageUrlFormdata(file)
                return false;
                // handleImageUpload(file);
              }}
              // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              // listType="picture-card"
              // fileList={fileList}
              // onChange={onChangeImage}
              onPreview={onPreview}
              maxCount={1}
              showUploadList={false}
            >
              <span className="image-preview-text btn btn-gradient">
                + Upload
              </span>
            </Upload>
          </ImgCrop>
          <div>
            <div></div>

            <div className="user_details">
              <label htmlFor="username" className="form-label">
                Username
              </label>
              <h3>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  value={
                    userDataDetails.username ? userDataDetails.username : ''
                  }
                  onChange={userDataDetailsHandler}
                  placeholder=""
                />
              </h3>

              <label htmlFor="designation" className="form-label mt-3">
                Designation
              </label>
              <h3>
                <input
                  type="text"
                  className="form-control"
                  id="designation"
                  value={
                    userDataDetails.designation
                      ? userDataDetails.designation
                      : ''
                  }
                  onChange={userDataDetailsHandler}
                  placeholder=""
                />
              </h3>

              <label htmlFor="company" className="form-label mt-3">
                Company
              </label>
              <h3>
                <input
                  type="text"
                  className="form-control"
                  id="company"
                  value={userDataDetails.company ? userDataDetails.company : ''}
                  onChange={userDataDetailsHandler}
                  placeholder=""
                />
              </h3>
            </div>
          </div>

          <div className="divider new-divider">
            <span></span>
          </div>

          {/* <h4>Click Below Icons to Contact</h4> */}
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="icons p-0 w-100">
                <ul id="myList_add" className="px-3">
                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="phone">Phone</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="phone"
                            checked={
                              checkBoxValues.phone
                                ? checkBoxValues.phone
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        value={formValues.phone ? formValues.phone : ''}
                        onChange={handleInputChange}
                        placeholder=""
                      />
                    </div>
                  </li>
                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="whatsapp">Whatsapp</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="whatsapp"
                            checked={
                              checkBoxValues.whatsapp
                                ? checkBoxValues.whatsapp
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.whatsapp ? formValues.whatsapp : ''}
                        onChange={handleInputChange}
                        id="whatsapp"
                        placeholder="CountryCode + Number"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="mail">Mail</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="mail"
                            checked={
                              checkBoxValues.mail ? checkBoxValues.mail : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.mail ? formValues.mail : ''}
                        onChange={handleInputChange}
                        id="mail"
                        placeholder=""
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="twitter">Twitter</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="twitter"
                            checked={
                              checkBoxValues.twitter
                                ? checkBoxValues.twitter
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.twitter ? formValues.twitter : ''}
                        onChange={handleInputChange}
                        id="twitter"
                        placeholder="Twitter Username"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="linkedin">Linkedin</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="linkedin"
                            checked={
                              checkBoxValues.linkedin
                                ? checkBoxValues.linkedin
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.linkedin ? formValues.linkedin : ''}
                        onChange={handleInputChange}
                        id="linkedin"
                        placeholder="Username"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="instagram">Instagram</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="instagram"
                            checked={
                              checkBoxValues.instagram
                                ? checkBoxValues.instagram
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.instagram ? formValues.instagram : ''}
                        onChange={handleInputChange}
                        id="instagram"
                        placeholder="Username"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="facebook">Facebook</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="facebook"
                            checked={
                              checkBoxValues.facebook
                                ? checkBoxValues.facebook
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.facebook ? formValues.facebook : ''}
                        onChange={handleInputChange}
                        id="facebook"
                        placeholder="Username"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="website">Website</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="website"
                            checked={
                              checkBoxValues.website
                                ? checkBoxValues.website
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.website ? formValues.website : ''}
                        onChange={handleInputChange}
                        id="website"
                        placeholder=""
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="location">Location</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="location"
                            checked={
                              checkBoxValues.location
                                ? checkBoxValues.location
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.location ? formValues.location : ''}
                        onChange={handleInputChange}
                        id="location"
                        placeholder="latitude,longitude"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="youtube">Youtube</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="youtube"
                            checked={
                              checkBoxValues.youtube
                                ? checkBoxValues.youtube
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.youtube ? formValues.youtube : ''}
                        onChange={handleInputChange}
                        id="youtube"
                        placeholder="URL"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="dribble">Dribble</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="dribble"
                            checked={
                              checkBoxValues.dribble
                                ? checkBoxValues.dribble
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.dribble ? formValues.dribble : ''}
                        onChange={handleInputChange}
                        id="dribble"
                        placeholder="Username"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="vimeo">Vimeo</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="vimeo"
                            checked={
                              checkBoxValues.vimeo
                                ? checkBoxValues.vimeo
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.vimeo ? formValues.vimeo : ''}
                        onChange={handleInputChange}
                        id="vimeo"
                        placeholder="Username"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="spotify">Spotify</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="spotify"
                            checked={
                              checkBoxValues.spotify
                                ? checkBoxValues.spotify
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.spotify ? formValues.spotify : ''}
                        onChange={handleInputChange}
                        id="spotify"
                        placeholder="Username"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="tiktok">Tiktok</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="tiktok"
                            checked={
                              checkBoxValues.tiktok
                                ? checkBoxValues.tiktok
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.tiktok ? formValues.tiktok : ''}
                        onChange={handleInputChange}
                        id="tiktok"
                        placeholder="UserId"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="pinterest">Pinterest</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="pinterest"
                            checked={
                              checkBoxValues.pinterest
                                ? checkBoxValues.pinterest
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.pinterest ? formValues.pinterest : ''}
                        onChange={handleInputChange}
                        id="pinterest"
                        placeholder="Username"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5" hidden>
                    <div>
                      <div className="switch-label">
                        <label htmlFor="soundcloud">Soundcloud</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="soundcloud"
                            checked={
                              checkBoxValues.soundcloud
                                ? checkBoxValues.soundcloud
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          formValues.soundcloud ? formValues.soundcloud : ''
                        }
                        onChange={handleInputChange}
                        id="soundcloud"
                        placeholder="URL"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5" hidden>
                    <div>
                      <div className="switch-label">
                        <label htmlFor="warwick">Warwick</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="warwick"
                            checked={
                              checkBoxValues.warwick
                                ? checkBoxValues.warwick
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.warwick ? formValues.warwick : ''}
                        onChange={handleInputChange}
                        id="warwick"
                        placeholder="URL"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="telegram">Telegram</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="telegram"
                            checked={
                              checkBoxValues.telegram
                                ? checkBoxValues.telegram
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.telegram ? formValues.telegram : ''}
                        onChange={handleInputChange}
                        id="telegram"
                        placeholder="Username"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5" hidden>
                    <div>
                      <div className="switch-label">
                        <label htmlFor="cute">Cute</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="cute"
                            checked={
                              checkBoxValues.cute ? checkBoxValues.cute : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.cute ? formValues.cute : ''}
                        onChange={handleInputChange}
                        id="cute"
                        placeholder="URL"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="snapchat">Snapchat</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="snapchat"
                            checked={
                              checkBoxValues.snapchat
                                ? checkBoxValues.snapchat
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.snapchat ? formValues.snapchat : ''}
                        onChange={handleInputChange}
                        id="snapchat"
                        placeholder="Username"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                    <div className='switch-label'>

                      <label htmlFor="threads" >
                       Threads
                      </label>

                      <label className="switch">
                        <input
                          type="checkbox"
                          className="toggle"
                          id="threads"
                          checked={
                            checkBoxValues.threads
                              ? checkBoxValues.threads
                              : false
                          }
                          onChange={handleCheckBoxChange}
                        />
                        <span className="slider"></span>
                      </label>
</div>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          formValues.threads
                            ? formValues.threads
                            :''
                        }
                        onChange={handleInputChange}
                        id="threads"
                        placeholder="Username"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5" hidden>
                    <div>
                      <div className="switch-label">
                        <label htmlFor="tumblr">Tumblr</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="tumblr"
                            checked={
                              checkBoxValues.tumblr
                                ? checkBoxValues.tumblr
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.tumblr ? formValues.tumblr : ''}
                        onChange={handleInputChange}
                        id="tumblr"
                        placeholder="Username"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="snabehancepchat">Behance</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="behance"
                            checked={
                              checkBoxValues.behance
                                ? checkBoxValues.behance
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.behance ? formValues.behance : ''}
                        onChange={handleInputChange}
                        id="behance"
                        placeholder="Username"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="dropbox">Dropbox</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="dropbox"
                            checked={
                              checkBoxValues.dropbox
                                ? checkBoxValues.dropbox
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.dropbox ? formValues.dropbox : ''}
                        onChange={handleInputChange}
                        id="dropbox"
                        placeholder="URL"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="skype">Skype</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="skype"
                            checked={
                              checkBoxValues.skype
                                ? checkBoxValues.skype
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.skype ? formValues.skype : ''}
                        onChange={handleInputChange}
                        id="skype"
                        placeholder="Skype Id"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5" hidden>
                    <div>
                      <div className="switch-label">
                        <label htmlFor="paypal">Paypal</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="paypal"
                            checked={
                              checkBoxValues.paypal
                                ? checkBoxValues.paypal
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.paypal ? formValues.paypal : ''}
                        onChange={handleInputChange}
                        id="paypal"
                        placeholder="Username"
                      />
                    </div>
                  </li>

                  <li className="mt-0 mb-3 mb-md-5">
                    <div>
                      <div className="switch-label">
                        <label htmlFor="flickr">Flickr</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="flickr"
                            checked={
                              checkBoxValues.flickr
                                ? checkBoxValues.flickr
                                : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.flickr ? formValues.flickr : ''}
                        onChange={handleInputChange}
                        id="flickr"
                        placeholder="URL"
                      />
                    </div>
                  </li>
                  <li className="mt-0 mb-3 mb-md-5" hidden>
                    <div>
                      <div className="switch-label">
                        <label htmlFor="rss">Rss</label>

                        <label className="switch">
                          <input
                            type="checkbox"
                            className="toggle"
                            id="rss"
                            checked={
                              checkBoxValues.rss ? checkBoxValues.rss : false
                            }
                            onChange={handleCheckBoxChange}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={formValues.rss ? formValues.rss : ''}
                        onChange={handleInputChange}
                        id="rss"
                        placeholder="URL"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div>
          <ToastContainer theme="dark" />
        </div>
        <ul className="btm_btn cls">
          <li>
            <button onClick={handleSaveContact}>SAVE</button>
          </li>
          <Link
            to={'/' + (userDataDetails.user_id ? userDataDetails.user_id : '')}
          >
            <li>
              <button>Preview</button>
            </li>
          </Link>

          <li>
            <button onClick={logout}>Logout</button>
          </li>
        </ul>
      </section>
      <footer>
        <ul className="fnav">
          <li>
            {/* <Link to="mailto:digiccard@gmail.com">Digiccard@gmail.com</Link> */}
            <a
              href="mailto:digiccard@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Digiccard@gmail.com
            </a>
          </li>
          <li>
            <Link to="/terms_and_condition">Terms & Condition</Link>
          </li>
        </ul>
        {/* <span className="powered nav-link">
        <Link to="/terms_and_condition">
                  Terms & Condition
                  </Link>
    </span> */}
      </footer>
    </div>
  );
}
