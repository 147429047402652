import './login.css';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { login, loginRequest } from '../redux/actions';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ActionTypes } from '../redux/constants';

const LoginForm = () => {
  const navigate = useNavigate();
  const reduxData = useSelector(state => state.login);
  const { userData, user, isLoading, error } = reduxData;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const jwtToken = localStorage.getItem('jwtToken');

  const dispatch = useDispatch();
  const handleSubmit = async e => {
    e.preventDefault();
    const LoginRequest = loginRequest();
    dispatch(LoginRequest);
    await login(email, password)
      .then(val => {
        if (val.type == ActionTypes.LOGIN_FAILURE) {
          toast.error(val.payload);
        }
        dispatch(val);
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (jwtToken) {
      navigate('/add-user'); // redirect to dashboard if login is successful
    }
  }, [user, userData, reduxData, navigate, jwtToken]);

  return (
    <div>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-0">
          <div className="container-fluid">
            <Link to="/">
              <span className="logo mt-0">
                <img src="../assets/images/logo.svg" alt="logo" />
              </span>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link className="nav-link active" to="/">
                    Home
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/register">
                    Register
                  </Link>
                </li>

                <li className="nav-item">
                <Link className="nav-link" to="tel:+971542747073">Contact</Link>
                  {/* <a className="nav-link" href="tel:+971542747073">Contact</a> */}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <section className="w-100 heightFix py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-md-1 loginForm-container regCont mt-5 mt-md-0">
              {/* <div className="d-block d-md-none text-center mb-4">
                <h1 className="fw-bold  d-none d-md-block">
                  Register to your account.
                </h1>
              </div> */}
              <div className="card rounded">
                <div className="card-body p-3 p-md-5">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label htmlFor="username" className="form-label">
                        Email
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        id="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="row">
                      <div className="col-md-6 order-md-2 mb-4 mb-md-0">
                        <div className="d-flex align-items-center h-100"></div>
                      </div>

                      <div className="col-md-6 order-md-1 text-center text-md-start">
                        {isLoading ? (
                          <p>Loading...</p>
                        ) : (
                          <button className="btn btn-gradient" type="submit">
                            Login
                          </button>
                        )}
                      </div>

                      <div className="col-12 order-md-3 mt-3 mt-md-5 fw-bold text-center text-md-start">
                        New user ? <Link to="/register">Register</Link>
                      </div>

                      <div>
                        <ToastContainer theme="dark" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <ul className="fnav">
          <li>
            {/* <Link to="mailto:digiccard@gmail.com">Digiccard@gmail.com</Link> */}
            <a
              href="mailto:digiccard@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Digiccard@gmail.com
            </a>
          </li>
          <li >
                  <Link to="/terms_and_condition">
                  Terms & Condition
                  </Link>
                </li>
         
          {/* <li>
            <Link to="tel:+971542747073">Digiccard@gmail.com</Link>
            <a href="tel:+971542747073">Contact</a>
          </li> */}
        </ul>
        {/* <span className="powered">Powered by  
        <a href="" className='ms-2'>Adzoob Digital Solutions</a>
    </span> */}
      </footer>
    </div>
  );
};
// const mapStateToProps = (state) => ({
//     isLoading: state.isLoading,
//     error: state.error,
// });

// const mapDispatchToProps = {
//     login,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
export default LoginForm;
