import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";

const Help = () => {
return (<div>
    <header>
       

<nav className="navbar navbar-expand-lg navbar-light bg-transparent">
  <div className="container-fluid">
    <a className="navbar-brand" href="#">
    <span className="logo mt-0">
        <img src="../assets/images/logo.svg" alt="logo"/>
    </span>
    </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav ms-auto">
        <li className="nav-item">
        <Link className='nav-link active' to="/">Home</Link>
          {/* <a className="nav-link active" aria-current="page" href="#">Home</a> */}
        </li>
      
        <li className="nav-item">
          <Link className="nav-link" to="/login">Login</Link>
        </li>
        
      </ul>
    </div>
  </div>
</nav>

</header>

<section>


<div className=" maincontainer containertest">
	<header className="bg-transparent">
		<h1>User Registration Steps</h1>
	</header>
	<div>
		<h4>Step 1: Click on the menu</h4>
      
		<p>Click on the menu to open the navigation options.</p>

</div>
<div>
        <h4 className="mt-5">Step 2: Select "Login" from the menu</h4>
        
		<p>From the menu, locate and select the "Login" option.</p>

    <div className="col-md-12">
		<h4 className="mt-5">Step 3: Click on the "Register" link</h4>
    <p>On the login page, locate and click on the "Register" link.</p>
</div>
<div className="col-md-12">
    <h4 className="mt-5">Step 4: Set Email, Password, One-Time Password, and Digic ID</h4>
</div>
    <p className='mb-10'>Type the Email and password that you want to set. In the "One-Time Password" and "Digic ID" fields, provide the information shared by the DIGIC team. Then, click on "Register".</p>

		<h4 className="mt-5">Step 5: Login</h4>
    <p>Once the registration is complete, navigate to the login screen and log in using your Username/email and password.</p>

    <h4 className="mt-5">Step 6: Add Data</h4>
    <p>After logging in, you can add all your data.</p>

    
        </div>

</div>



</section>

<footer className="mt-5">
<ul className="fnav">
          <li>
            {/* <Link to="mailto:digiccard@gmail.com">Digiccard@gmail.com</Link> */}
            <a
              href="mailto:digiccard@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Digiccard@gmail.com
            </a>
          </li>
          <li>
            {/* <Link to="tel:+971542747073">Digiccard@gmail.com</Link> */}
            <a href="tel:+971542747073">Contact</a>
          </li>
        </ul>
    {/* <span className="powered">Powered by 
        <a href="" className='ms-2'>Adzoob Digital Solutions</a>
    </span> */}
</footer>

</div>)

}
export default Help;